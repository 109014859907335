// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import { xcss, Box } from '@atlaskit/primitives'
import Button, { IconButton } from '@atlaskit/button/new'
import CameraTakePictureIcon from '@atlaskit/icon/glyph/camera-take-picture'
import VidPauseIcon from '@atlaskit/icon/glyph/vid-pause'
import EditorTextColorIcon from '@atlaskit/icon/glyph/editor/text-color'
import styled from 'styled-components'
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import { token } from '@atlaskit/tokens'

const StartButtonWrapper = styled.div`
  position: absolute;
  inset: 50% 0 0 50%;
  width: 200px;
  height: 60px;
  margin-left: -100px;
  margin-top: -20px;
  text-align: center;
  button {
    background-color: #fff;
  }
  button span {
    display: flex;
    align-items: center;
  }
`

const StopButtonWrapper = styled.div`
  position: absolute;
  inset: 100% 0 0 50%;
  width: 280px;
  height: 60px;
  margin-left: -40px;
  margin-top: -50px;
  display: flex;
  gap: ${token('space.200')};
  button {
    background-color: #fff;
    &:active {
      background-color: rgba(255, 255, 255, 0.32);
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.24);
    }
    span:hover {
      background-color: transparent !important;
    }
    span:active {
      background-color: transparent !important;
    }
  }
`

const VideoRecorder = ({
  setVideoURL,
  setVideoBlob,
}: {
  setVideoURL: (arg: string) => void
  setVideoBlob: (arg: Blob | null) => void
}) => {
  const [recordingState, setRecordingState] = useState('init')
  const [videoDuration, setVideoDuration] = useState(0)
  const videoMediaRecorderRef = useRef<MediaRecorder | null>(null)
  const audioMediaRecorderRef = useRef<MediaRecorder | null>(null)
  const isTimerRunning = useRef(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const videoChunksRef = useRef<Blob[]>([])
  const audioChunksRef = useRef<Blob[]>([])
  const timer = useRef<any>(null)
  const recorderTime = useRef<number>(0)

  async function startStream() {
    console.log('startStream')
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { width: 360, height: 253 },
      audio: true,
    })
    if (videoRef.current) {
      videoRef.current.removeEventListener('ended', videoOnEnd)
      // videoRef.current.removeEventListener("timeupdate", videoOnUpdate);
      videoRef.current.addEventListener('ended', videoOnEnd)
      // videoRef.current.addEventListener("timeupdate", videoOnUpdate);
      videoRef.current.srcObject = stream
      videoRef.current.play()
    }

    const videoStream = new MediaStream(stream)
    const videMimeType = MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4'
    const audioStream = new MediaStream(stream.getAudioTracks())
    const audioMimeType = MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : 'audio/mp4'

    videoMediaRecorderRef.current = new MediaRecorder(videoStream, { mimeType: videMimeType })
    audioMediaRecorderRef.current = new MediaRecorder(audioStream, { mimeType: audioMimeType })

    videoMediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        videoChunksRef.current.push(event.data)
      }
    }
    audioMediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data)
      }
    }

    const onStartOnResume = () => {
      if (!isTimerRunning.current) {
        isTimerRunning.current = true
        timer.current = setInterval(() => {
          setVideoDuration((val) => val + 1)
        }, 1000)
      }
    }
    const onPauseOnStop = () => {
      clearInterval(timer.current)
      isTimerRunning.current = false
    }

    videoMediaRecorderRef.current.onstart = onStartOnResume
    videoMediaRecorderRef.current.onresume = onStartOnResume
    videoMediaRecorderRef.current.onpause = onPauseOnStop
    videoMediaRecorderRef.current.onstop = onPauseOnStop

    audioMediaRecorderRef.current.onstart = onStartOnResume
    audioMediaRecorderRef.current.onresume = onStartOnResume
    audioMediaRecorderRef.current.onpause = onPauseOnStop
    audioMediaRecorderRef.current.onstop = onPauseOnStop

    videoMediaRecorderRef.current.start(1000)
    audioMediaRecorderRef.current.start(1000)
  }

  const startRecording = async () => {
    console.log('startRecording')
    videoChunksRef.current = []
    audioChunksRef.current = []
    if (videoRef.current) {
      videoRef.current.muted = true
    }
    await startStream()

    setRecordingState('started')
  }

  const stopRecording = () => {
    console.log('stopRecording')
    recorderTime.current = videoDuration
    isTimerRunning.current = false
    if (audioMediaRecorderRef.current) {
      const blob = new Blob(audioChunksRef.current, { type: audioMediaRecorderRef.current.mimeType || 'audio/webm' })
      const url = URL.createObjectURL(blob)
      setVideoURL(url)
      setVideoBlob(blob)
      audioMediaRecorderRef.current.stop()
      audioChunksRef.current = []
    }

    if (videoMediaRecorderRef.current) {
      const blob = new Blob(videoChunksRef.current, { type: videoMediaRecorderRef.current.mimeType || 'video/webm' })
      const url = URL.createObjectURL(blob)
      if (videoRef.current) {
        if (videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject as MediaStream
          stream.getTracks().forEach((track) => track.stop())
        }

        videoRef.current.pause()
        videoRef.current.srcObject = null
        videoRef.current.src = url
      }
      videoMediaRecorderRef.current.stop()
      videoChunksRef.current = []
    }
    setRecordingState('stopped')
  }

  const pauseRecording = () => {
    console.log('pauseRecording')
    if (audioMediaRecorderRef.current) {
      audioMediaRecorderRef.current.pause()
    }
    if (videoMediaRecorderRef.current) {
      videoMediaRecorderRef.current.pause()
      if (videoRef.current) {
        videoRef.current.pause()
      }
    }

    clearInterval(timer.current)
    setRecordingState('paused')
  }

  const resumeRecording = () => {
    console.log('resumeRecording')
    if (audioMediaRecorderRef.current) {
      audioMediaRecorderRef.current.resume()
    }
    if (videoMediaRecorderRef.current) {
      videoMediaRecorderRef.current.resume()
      if (videoRef.current) {
        videoRef.current.play()
      }
    }
    setRecordingState('started')
  }

  function cleanRecording() {
    console.log('cleanRecording')
    clearInterval(timer.current)
    setVideoDuration(0)
    isTimerRunning.current = false
    setRecordingState('init')
    if (audioMediaRecorderRef.current) {
      audioMediaRecorderRef.current.stop()
      setVideoURL('')
      setVideoBlob(null)
      audioChunksRef.current = []
    }
    if (videoMediaRecorderRef.current) {
      videoMediaRecorderRef.current.stop()
      if (videoRef.current) {
        videoRef.current.srcObject = null
        videoRef.current.pause()
        videoRef.current.src = ''
      }
      videoChunksRef.current = []
    }
  }

  const playRecording = () => {
    console.log('playRecording')
    if (videoRef.current) {
      videoRef.current.play()
      videoRef.current.muted = false
      timer.current = setInterval(() => {
        setVideoDuration((val) => val - 1)
      }, 1000)

      setRecordingState('playing')
    }
  }

  const pausePlaying = () => {
    console.log('pausePlaying')
    if (videoRef.current) {
      videoRef.current.pause()
      clearInterval(timer.current)
      setRecordingState('stopped')
    }
  }

  function videoOnEnd() {
    console.log('videoOnEnd')
    setRecordingState('stopped')
    clearInterval(timer.current)
    setVideoDuration(recorderTime.current)
  }

  // function videoOnUpdate() {
  //   if (videoRef.current) {
  //     setVideoDuration(videoRef.current.currentTime);
  //   }
  // }

  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Number((seconds % 60).toFixed(0))
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  useEffect(
    () => () => {
      if (videoMediaRecorderRef.current) {
        videoMediaRecorderRef.current.stop()
      }
      if (audioMediaRecorderRef.current) {
        audioMediaRecorderRef.current.stop()
      }
    },
    [],
  )

  useEffect(() => {
    if (videoDuration > 600) {
      stopRecording()
    }
  }, [videoDuration])

  return (
    <Box>
      <Box xcss={xcss({ position: 'relative' })}>
        <video
          // controls
          ref={videoRef}
          // autoPlay
          muted
          style={{
            backgroundColor: '#FFF4F3',
            width: '506px',
            height: '356px',
          }}
        />
        <VideoControls
          recordingState={recordingState}
          videoDuration={formatTime(videoDuration)}
          recording={{
            pause: pauseRecording,
            resume: resumeRecording,
            start: startRecording,
            stop: stopRecording,
            play: videoRef.current ? playRecording : () => {},
            pausePlaying,
            clean: cleanRecording,
          }}
        />
      </Box>
    </Box>
  )
}

function EditorTextColorIconRed() {
  return <EditorTextColorIcon label="stop" primaryColor={token('color.border.danger')} />
}

export default VideoRecorder

function VideoControls({
  recordingState,
  videoDuration,
  recording,
}: {
  recordingState: string
  videoDuration: string
  recording: { [key: string]: () => void }
}) {
  switch (recordingState) {
    case 'init':
      return (
        <StartButtonWrapper>
          <Button onClick={recording.start}>
            <CameraTakePictureIcon label="record" primaryColor={token('color.border.danger')} />
            Почати запис
          </Button>
        </StartButtonWrapper>
      )
    case 'stopped':
      return (
        <StopButtonWrapper>
          <IconButton icon={VidPlayIcon} label="Play" onClick={recording.play} />
          <IconButton icon={TrashIcon} label="Delete" onClick={recording.clean} />
          <DurationBlock videoDuration={videoDuration} />
        </StopButtonWrapper>
      )
    case 'started':
      return (
        <StopButtonWrapper>
          <IconButton icon={VidPauseIcon} label="Pause" onClick={recording.pause} />
          <IconButton icon={EditorTextColorIconRed} label="Stop" onClick={recording.stop} />
          <DurationBlock videoDuration={videoDuration} />
        </StopButtonWrapper>
      )
    case 'paused':
      return (
        <StopButtonWrapper>
          <IconButton icon={CameraTakePictureIcon} label="Start" onClick={recording.resume} />{' '}
          <IconButton icon={EditorTextColorIconRed} label="Stop" onClick={recording.stop} />
          <DurationBlock videoDuration={videoDuration} />
        </StopButtonWrapper>
      )
    case 'playing':
      return (
        <StopButtonWrapper>
          <IconButton icon={VidPauseIcon} label="Pause" onClick={recording.pausePlaying} />
          <IconButton icon={TrashIcon} label="Delete" onClick={recording.clean} />
          <DurationBlock videoDuration={videoDuration} />
        </StopButtonWrapper>
      )
    default:
      return <></>
  }
}

function DurationBlock({ videoDuration }: { videoDuration: string }) {
  return (
    <Box
      xcss={xcss({
        marginLeft: '132px',
        backgroundColor: 'elevation.surface',
        borderRadius: 'border.radius.100',
        padding: 'space.050',
        height: '32px',
        color: 'color.text.subtle',
        fontFamily: 'inherit',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        width: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      {videoDuration}
    </Box>
  )
}
